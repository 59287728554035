import { Home } from '@fluentsolar/lib-stats/page-HomePage/HomePage';
import * as React from 'react';



const HomePage = () => {
  return (
    <div className="w-full min-h-screen theme-general theme-dark bg-primary text-normal">
      <Home />
    </div>
  );
};

export default HomePage;

// triggers

